$(function() {
  // MENU
  var menu = {
    menu: '.menu',
    menuBox: '.menu__box',
    menuLink: '.menu__link',
    menuBurger: '.menu__burger',
    submenu: '.menu__submenu',
    openMenu: function() {
      $(menu.menu).addClass('active');
    },
    closeMenu: function() {
      $(menu.menu).removeClass('active');
    }
  };

  $(menu.menuLink).click(function(e) {
    if($(this).next(menu.submenu).length) {
      e.preventDefault();
    }
  });

  $(menu.menuBurger).click(function() {
    var isActiveMenu = $(menu.menu).hasClass('active');
    if (isActiveMenu) {
      menu.closeMenu();
    } else {
      menu.openMenu();
    }
  });

  //ACCORDION
  var accordion = {
    base: '.accordion',
    head: '.accordion__head',
    open: function(item) {
      $(item).addClass('active');
      $(item).next().slideDown();
    },
    close: function() {
      $(accordion.head).removeClass('active');
      $(accordion.head).next().slideUp();
    },
    init: function () {

      var location = window.location.hash;
      var hashItem = $(accordion.base).find(location);
      var hashIsActive = false;
      var hashHead = null;

      $(accordion.head).on('click', function() {
        var isActive = $(this).hasClass('active');

        accordion.close();

        if (!isActive) {
          accordion.open($(this));
          }
      });

      if (hashItem.length) {
        hashHead = hashItem.find(accordion.head)
        hashIsActive = hashHead.hasClass('active');

        if (hashIsActive) return;

        accordion.close();
        accordion.open(hashHead);
      }
    }
  };

  accordion.init();

  // SUBMIT CALC FORM
  $('.jsCalcBtn').click(function() {
    $(this).closest('form.calc').submit();
  });

  //SHOW POPUP
  $('[data-popup-id]').click(function(e) {
    e.preventDefault();
    var keyPopup = $(this).data('popupId');
    $('#' + keyPopup).arcticmodal();
  });
});